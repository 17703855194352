<template>
  <a-modal
    destroy-on-close
    v-model="visibleMutable"
    :width="800"
    @ok="handleSubmit"
    ok-text="保存"
    :confirm-loading="submiting"
  >
    <a-form-model ref="form" class="container">
      <div class="btn-box">
        <a-button type="link" @click="fetchData">刷新</a-button>
        <a-button type="link" @click="add">添加佣金</a-button>
        <a-popconfirm placement="topRight" :title="`确定清空佣金吗？`" ok-text="确定" cancel-text="取消" @confirm="empty()">
          <a-button type="link">清空佣金</a-button>
        </a-popconfirm>
      </div>
      <a-table
        size="small"
        :columns="outerColumns"
        :data-source="tableData"
        :pagination="false"
        :loading="tableLoading"
        row-key="commisionId"
      >
        <template #commision="record">
          <a-form-model-item
            class="inner-fm-item"
          >
            <a-input :disabled="record.levelId === '70' || record.levelId === '80' || record.levelId === '90'"
              v-model="record.commision"
              placeholder="请输入"
            ></a-input>
          </a-form-model-item>
        </template>
        <template #jqCommision="record">
          <a-form-model-item
            class="inner-fm-item"
          >
            <a-input :disabled="record.levelId === '70' || record.levelId === '80' || record.levelId === '90'"
                     v-model="record.jqCommision"
                     placeholder="请输入"
            ></a-input>
          </a-form-model-item>
        </template>
        <template #dlCommision="record">
          <a-form-model-item
            class="inner-fm-item"
          >
            <a-input :disabled="record.levelId !== '70' && record.levelId !== '80' && record.levelId !== '90'"
                     v-model="record.dlCommision"
                     placeholder="请输入"
            ></a-input>
          </a-form-model-item>
        </template>
      </a-table>
    </a-form-model>
  </a-modal>
</template>

<script>
import { computed, onMounted, watch, ref } from '@vue/composition-api'
import { agent, goods } from '@/api'
import Vue from 'vue'
import { message } from 'ant-design-vue'
import { checkConflict, EndPoint, Range, wrapAwait } from '@/utils/tools'

export default {
  name: 'ModalCommSetting',
  props: {
    visible: Boolean,
    productId: String,
  },
  events: [
    'update:visible'
  ],
  setup (props, { emit }) {
    const visibleMutable = computed({
      get: () => props.visible,
      set: value => {
        emit('update:visible', value)
      }
    })

    // 外层表头
    const outerColumns = [
      {
        title: '会员等级',
        dataIndex: 'levelName'
      },
      {
        title: '佣金（元）',
        scopedSlots: {
          customRender: 'commision'
        }
      },
      {
        title: '加权分红（元）',
        scopedSlots: {
          customRender: 'jqCommision'
        }
      },
      {
        title: '代理提成（元）',
        scopedSlots: {
          customRender: 'dlCommision'
        }
      }
    ]
    const tableData = ref([])
    const levelList = ref([])
    const tableLoading = ref(false)
    onMounted(() => {
      watch(() => props.productId, (id) => {
        if (id) fetchData()
      }, { immediate: true })
    })
    async function fetchData () {
      getLevelList()
      tableLoading.value = true
      const { data } = await goods.listCommision(props.productId)
      tableLoading.value = false
      tableData.value = data
    }
    async function getLevelList () {
      const { data, code } = await agent.getTeamLevelList({ relationType: 1 })
      if (code === '00000') {
        levelList.value = data
      }
    }
    async function add () {
      if (tableData.value.length > 0) {
        message.warning('已经存在佣金信息，请先清空')
        return
      }
      let arr = []
      for (let level of levelList.value) {
        arr.push({
          levelId: level.levelId,
          levelName: level.levelName,
          productId: props.productId,
          commision: 0,
          jqCommision: 0,
          dlCommision: 0,
        })
      }
      arr.push({
        levelId: '70',
        levelName: '区县代理',
        productId: props.productId,
        commision: 0,
        jqCommision: 0,
        dlCommision: 0,
      })
      arr.push({
        levelId: '80',
        levelName: '市级代理',
        productId: props.productId,
        commision: 0,
        jqCommision: 0,
        dlCommision: 0,
      })
      arr.push({
        levelId: '90',
        levelName: '省级代理',
        productId: props.productId,
        commision: 0,
        jqCommision: 0,
        dlCommision: 0,
      })
      tableData.value = arr
    }
    async function empty () {
      await goods.emptyCommision(props.productId)
      tableData.value = []
    }

    function genUUID (prefix) {
      return `${prefix}_${Date.now()}`
    }

    const rules = {
      start: [
        { required: true, message: '请输入' },
        { pattern: /^[0-9]+$/, message: '格式不正确' }
      ],
      price: [
        { required: true, message: '请输入' },
        { pattern: /^(0|[1-9][0-9])*(\.[0-9]+)?$/, message: '格式不正确' }
      ]
    }

    function validate () {
      let valid = true
      let error = ''
      let data = tableData.value
      let commision = 0
      let jqCommision = 0
      let commisionFlag = false
      for (let index in data) {
        if (data[index].commision > 0 || data[index].jqCommision > 0 || data[index].dlCommision > 0) {
          commisionFlag = true
        }
        if (data[index].levelId !== '70' && data[index].levelId !== '80' && data[index].levelId !== '90') {
          if (Number(data[index].commision) < commision) {
            valid = false
            error = '佣金设置错误'
            return {
              error,
              valid
            }
          } else {
            commision = Number(data[index].commision)
          }
          if (Number(data[index].jqCommision) < jqCommision) {
            valid = false
            error = '加权分红设置错误'
            return {
              error,
              valid
            }
          } else {
            jqCommision = Number(data[index].jqCommision)
          }
        }
      }
      if (!commisionFlag) {
        valid = false
        error = '请至少设置一个佣金金额'
      }
      return {
        error,
        valid
      }
    }

    function validateRule (rules, value, record) {
      for (const rule of rules) {
        if (!rule.required && !value) {
          break
        } else if (rule.required && !value) {
          return rule.message
        } else if (rule.pattern && !rule.pattern.test(value)) {
          return rule.message
        } else if (typeof rule.validate === 'function') {
          return rule.validate(value, record)
        }
      }
    }

    const submiting = ref(false)
    async function handleSubmit () {
      const { valid, error } = validate()
      if (!valid) return message.warning(error)
      if (!valid) return
      submiting.value = true
      const [err] = await wrapAwait(goods.saveCommision(tableData.value))
      submiting.value = false
      if (err) {
        message.error(err.message)
      } else {
        message.success('设置成功')
        visibleMutable.value = false
      }
    }

    return {
      visibleMutable,
      outerColumns,
      levelList,
      tableData,
      tableLoading,
      add,
      empty,
      fetchData,
      getLevelList,
      handleSubmit,
      submiting,
    }
  }
}
</script>

<style scoped>
.sku-image {
  width: 50px;
}
.container {
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}
.inner-fm-item {
  margin-bottom: 0;
}
.inner-fm-item ::v-deep .ant-form-explain {
  position: absolute;
  margin-top: -5px;
  font-size: 12px;
}
.inner-fm-item .btn.start {
  margin-right: -11px;
}
.inner-fm-item .btn.end {
  margin-right: -11px;
}
.inner-fm-item .btn.start, .inner-fm-item .btn.end {
  padding: 0 7px;
}
</style>
