<template>
  <a-modal
    destroy-on-close
    v-model="visibleMutable"
    :width="800"
    @ok="handleSubmit"
    ok-text="保存"
    :confirm-loading="submiting"
  >
    <a-form-model ref="form" class="container">
      <div class="btn-box">
        <a-button type="link" @click="fetchData">刷新</a-button>
      </div>
      <a-table
        :columns="outerColumns"
        :data-source="tableData"
        :pagination="false"
        :loading="tableLoading"
        row-key="productSkuId"
        :expanded-row-keys="expandedRows"
        @expand="handleExpand"
      >
        <template #skuImage="_, record">
          <base-img class="sku-image" :src="record.skuPic || record.mainPicPath" />
        </template>
        <template #operation="_, record">
          <div class="table-operations">
            <a-button type="link" @click="addStep(record)">增加</a-button>
          </div>
        </template>
        <template #expandedRowRender="record">
          <a-table
            size="small"
            :columns="innerColumns"
            :data-source="record.stepPriceList"
            :pagination="false"
            row-key="id"
          >
            <template #start="ir">
              <a-form-model-item
                class="inner-fm-item"
                :help="ir.errMsgOfStart"
                :validate-status="ir.errMsgOfStart ? 'error' : 'success'"
              >
                <a-input
                  v-model="ir.start"
                  placeholder="请输入"
                ></a-input>
              </a-form-model-item>
            </template>
            <template #price="ir">
              <a-form-model-item
                class="inner-fm-item"
                :help="ir.errMsgOfPrice"
                :validate-status="ir.errMsgOfPrice ? 'error' : 'success'"
              >
                <a-input
                  v-model="ir.price"
                  placeholder="请输入"
                ></a-input>
              </a-form-model-item>
            </template>
            <template #operation="ir">
              <div class="table-operations">
                <a-button type="link" @click="deleteStep(record, ir.id)">删除</a-button>
              </div>
            </template>
          </a-table>
        </template>
      </a-table>
    </a-form-model>
  </a-modal>
</template>

<script>
import { computed, onMounted, watch, ref } from '@vue/composition-api'
import { goods } from '@/api'
import Vue from 'vue'
import { message } from 'ant-design-vue'
import { checkConflict, EndPoint, Range, wrapAwait } from '@/utils/tools'

export default {
  name: 'ModalBatchSetting',
  props: {
    visible: Boolean,
    productId: String,
  },
  events: [
    'update:visible'
  ],
  setup (props, { emit }) {
    const visibleMutable = computed({
      get: () => props.visible,
      set: value => {
        emit('update:visible', value)
      }
    })

    // 外层表头
    const outerColumns = [
      {
        title: '规格名称',
        dataIndex: 'skuName'
      },
      {
        title: '规格图片',
        scopedSlots: {
          customRender: 'skuImage'
        }
      },
      {
        title: '商品零售价（元）',
        dataIndex: 'price'
      },
      {
        title: '供货价（元）',
        dataIndex: 'tradePrice'
      },
      {
        title: '操作',
        scopedSlots: {
          customRender: 'operation'
        }
      }
    ]
    const tableData = ref([])
    const tableLoading = ref(false)
    onMounted(() => {
      watch(() => props.productId, (id) => {
        if (id) fetchData()
      }, { immediate: true })
    })
    async function fetchData () {
      tableLoading.value = true
      const { data } = await goods.getSkuAndStepPrice(props.productId)
      tableLoading.value = false
      tableData.value = processDataForClient(data)
      expandedRows.value = tableData.value.filter(x => x.stepPriceList.length).map(x => x.productSkuId)
    }
    function processDataForClient (data) {
      return data.map(x => ({
        ...x,
        stepPriceList: (x.listPrice || []).map(x => ({
          id: x.productSkuStepPriceId,
          start: x.startNumStr,
          includeStartFlag: x.startFlag,
          end: x.endNumStr,
          includeEndFlag: x.endFlag,
          price: x.stepPrice,
          errMsgOfStart: '',
          errMsgOfEnd: '',
          errMsgOfPrice: '',
        }))
      }))
    }
    function processDataForServer (data) {
      const result = []
      for (const sku of data) {
        const list = sku.stepPriceList.slice().sort((x, y) => x.start - y.start)
        for (let i = 0; i < list.length; i++) {
          const step = list[i]
          const newStep = list[i + 1]
          // result.push({
          //   startFlag: step.includeStartFlag,
          //   startNumStr: step.start,
          //   endFlag: step.includeEndFlag,
          //   endNumStr: step.end,
          //   productId: sku.productId,
          //   productSkuId: sku.productSkuId,
          //   stepPrice: step.price,
          //   seqNum: i
          // })
          result.push({
            startFlag: true,
            startNumStr: step.start,
            endFlag: false,
            endNumStr: newStep ? newStep.start : '',
            productId: sku.productId,
            productSkuId: sku.productSkuId,
            stepPrice: step.price,
            seqNum: i
          })
        }
      }
      return result
    }

    const expandedRows = ref([])
    function addStep (sku) {
      if (!expandedRows.value.includes(sku.productSkuId)) {
        expandedRows.value.push(sku.productSkuId)
      }
      sku.stepPriceList.push(createStep())
    }
    function handleExpand (expanded, record) {
      const id = record.productSkuId
      const index = expandedRows.value.indexOf(id)
      if (index > -1) {
        if (!expanded) {
          expandedRows.value.splice(index, 1)
        }
      } else {
        if (expanded) {
          expandedRows.value.push(id)
        }
      }
    }

    function createStep () {
      return {
        id: genUUID('sp'),
        start: '',
        includeStartFlag: true,
        end: '',
        includeEndFlag: false,
        price: '',
        errMsgOfStart: '',
        errMsgOfEnd: '',
        errMsgOfPrice: '',
      }
    }

    function genUUID (prefix) {
      return `${prefix}_${Date.now()}`
    }

    // 内层表头
    const innerColumns = [
      {
        title: '开始数量（含）',
        scopedSlots: {
          customRender: 'start'
        }
      },
      {
        title: '商品单价',
        scopedSlots: {
          customRender: 'price'
        }
      },
      {
        title: '操作',
        scopedSlots: {
          customRender: 'operation'
        }
      }
    ]

    function deleteStep (sku, id) {
      const index = sku.stepPriceList.findIndex(x => x.id === id)
      sku.stepPriceList.splice(index, 1)
    }

    const rules = {
      start: [
        { required: true, message: '请输入' },
        { pattern: /^[0-9]+$/, message: '格式不正确' }
      ],
      // end: [
      //   { pattern: /^[1-9][0-9]*$/, message: '格式不正确' },
      //   {
      //     validate (value, record) {
      //       try {
      //         const range = new Range(new EndPoint(record.start, record.includeStartFlag), new EndPoint(record.end, record.includeEndFlag))
      //       } catch (e) {
      //         console.log(e)
      //         return '无效的区间'
      //       }
      //     }
      //   }
      // ],
      price: [
        { required: true, message: '请输入' },
        { pattern: /^(0|[1-9][0-9])*(\.[0-9]+)?$/, message: '格式不正确' }
      ]
    }

    function validate () {
      let valid = true
      let hasAtLeastOne = false
      for (const sku of tableData.value) {
        for (const s of sku.stepPriceList) {
          hasAtLeastOne = true
          let t
          if ((t = validateRule(rules.start, s.start, s))) {
            s.errMsgOfStart = t
            valid = false
          } else {
            s.errMsgOfStart = ''
          }
          // if ((t = validateRule(rules.end, s.end, s))) {
          //   s.errMsgOfEnd = t
          //   valid = false
          // } else {
          //   s.errMsgOfEnd = ''
          // }
          if ((t = validateRule(rules.price, s.price, s))) {
            s.errMsgOfPrice = t
            valid = false
          } else {
            s.errMsgOfPrice = ''
          }
        }
      }
      return {
        hasAtLeastOne,
        valid
      }
    }

    function validateRule (rules, value, record) {
      for (const rule of rules) {
        if (!rule.required && !value) {
          break
        } else if (rule.required && !value) {
          return rule.message
        } else if (rule.pattern && !rule.pattern.test(value)) {
          return rule.message
        } else if (typeof rule.validate === 'function') {
          return rule.validate(value, record)
        }
      }
    }

    function checkRange () {
      // for (const sku of tableData.value) {
      //   const result = checkConflict(
      //     ...sku.stepPriceList.map(x =>
      //       new Range(
      //         new EndPoint(x.start, x.includeStartFlag),
      //         new EndPoint(x.end, x.includeEndFlag)
      //       )
      //     )
      //   )
      //   if (result) {
      //     return `区间${result[0].print()}和区间${result[1].print()}有重合`
      //   }
      // }
      for (const sku of tableData.value) {
        const set = new Set()
        for (const item of sku.stepPriceList) {
          if (set.has(+item.start)) {
            return `开始数量重复，请检查`
          }
          set.add(+item.start)
        }
      }
    }

    const submiting = ref(false)
    async function handleSubmit () {
      const { valid, hasAtLeastOne } = validate()
      if (!hasAtLeastOne) return message.warning('请至少设置一条规则')
      if (!valid) return
      const msg = checkRange()
      if (msg) return message.warning(msg)
      const prepared = processDataForServer(tableData.value)
      submiting.value = true
      const [err] = await wrapAwait(goods.saveStepPrice(prepared))
      submiting.value = false
      if (err) {
        message.error(err.message)
      } else {
        message.success('设置成功')
        visibleMutable.value = false
      }
    }

    return {
      visibleMutable,
      outerColumns,
      innerColumns,
      tableData,
      tableLoading,
      fetchData,
      addStep,
      expandedRows,
      deleteStep,
      handleExpand,
      handleSubmit,
      submiting,
    }
  }
}
</script>

<style scoped>
.sku-image {
  width: 50px;
}
.container {
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}
.inner-fm-item {
  margin-bottom: 0;
}
.inner-fm-item ::v-deep .ant-form-explain {
  position: absolute;
  margin-top: -5px;
  font-size: 12px;
}
.inner-fm-item .btn.start {
  margin-right: -11px;
}
.inner-fm-item .btn.end {
  margin-right: -11px;
}
.inner-fm-item .btn.start, .inner-fm-item .btn.end {
  padding: 0 7px;
}
</style>
